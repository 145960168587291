import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNextVisitAction,
  updateNextVisitCompleteAction,
} from "../../actions/NextVisitAction";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const NextVisitScreen = () => {
  const { nextVisits, loading, total } = useSelector(
    (state) => state.getNextVisitReducer
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [searchParams, setSearchParams] = useState({
    name: "",
    phone: "",
    code: "",
    dateFrom: "",
    dateTo: "",
  });

  const history = useHistory();

  useEffect(() => {
    dispatch(getNextVisitAction(page, perPage, searchParams));
  }, [page, perPage]);
  const handleSearchAction = () => {
    dispatch(getNextVisitAction(page, perPage, searchParams));
  };

  const handleComplete = (id) => {
    updateNextVisitCompleteAction(id);
    dispatch(getNextVisitAction(page, perPage, searchParams));
    dispatch(getNextVisitAction(page, perPage, searchParams));
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  return (
    <div className=" mt-4">
      <h2 className="mb-4">Next Visits</h2>
      <div className="row mb-3">
        <div className="col-md-3">
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Search by patient name"
            value={searchParams.name}
            onChange={handleSearch}
          />
        </div>
        <div className="col-md-3">
          <input
            type="text"
            name="phone"
            className="form-control"
            placeholder="Search by patient phone"
            value={searchParams.phone}
            onChange={handleSearch}
          />
        </div>
        <div className="col-md-3">
          <input
            type="text"
            name="code"
            className="form-control"
            placeholder="Search by patient code"
            value={searchParams.code}
            onChange={handleSearch}
          />
        </div>
        <div className="col-md-3">
          <input
            type="date"
            name="dateFrom"
            className="form-control"
            value={searchParams.dateFrom}
            onChange={handleSearch}
          />
        </div>
        <div className="col-md-3 mt-2">
          <input
            type="date"
            name="dateTo"
            className="form-control"
            value={searchParams.dateTo}
            onChange={handleSearch}
          />
        </div>

        <div>
          <button
            className="btn btn-primary  mt-2"
            onClick={handleSearchAction}
          >
            Search
          </button>
        </div>
      </div>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>#</th>
            <th>Code</th>
            <th>Patient Name</th>
            <th>Patient Phone</th>
            <th>Visitations</th>
            <th>Next Visit Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {nextVisits?.map((next, index) => {
            return (
              <tr key={index}>
                <td
                  onClick={(e) => {
                    history.push(`/tabsVisit/${next.visit.patient.id}`, {
                      patientKey: next.visit,
                    });
                  }}
                >
                  {index + 1}
                </td>
                <td
                  onClick={(e) => {
                    history.push(`/tabsVisit/${next.visit.patient.id}`, {
                      patientKey: next.visit,
                    });
                  }}
                >
                  {next.visit.patient.code}
                </td>
                <td
                  onClick={(e) => {
                    history.push(`/tabsVisit/${next.visit.patient.id}`, {
                      patientKey: next.visit,
                    });
                  }}
                >
                  {next.visit.patient.fullname}
                </td>
                <td
                  onClick={(e) => {
                    history.push(`/tabsVisit/${next.visit.patient.id}`, {
                      patientKey: next.visit,
                    });
                  }}
                >
                  {next.visit.patient.phone}
                </td>
                <td
                  onClick={(e) => {
                    history.push(`/tabsVisit/${next.visit.patient.id}`, {
                      patientKey: next.visit,
                    });
                  }}
                >
                  <p
                    className="text-red text-bold  "
                    style={{
                      textDecoration: "underline",
                      fontSize: "20px",
                    }}
                  >
                    {next.visit.patient.visits}
                  </p>
                </td>
                <td
                  onClick={(e) => {
                    history.push(`/tabsVisit/${next.visit.patient.id}`, {
                      patientKey: next.visit,
                    });
                  }}
                >
                  {next.next_visit_date}
                </td>
                <td
               
                >
                  <button
                    onClick={() => handleComplete(next.id)}
                    className="btn btn-info"
                  >
                    Complete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {total > 0 ? (
        <div className="center d-flex justify-content-center align-items-center mb-5">
          <div className="mr-2">
            <ReactPaginate
              //   forcePage={0}
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              onPageChange={(ev) => {
                dispatch(getNextVisitAction(ev.selected + 1, perPage));
                setPage(ev.selected);
              }}
              pageNum={page}
              forcePage={page}
              pageCount={total / perPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
          <div>
            <select
              value={perPage}
              onChange={(e) => setPerPage(e.target.value)}
              className="form-control border-info "
              placeholder="count"
            >
              <option value="10" selected>
                10
              </option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      ) : (
        <div>
          <br />
        </div>
      )}
    </div>
  );
};

export default NextVisitScreen;
