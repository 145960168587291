import swal from "sweetalert";
import { createBrowserHistory } from "history";

import {
  ADD_PATINET,
  ADD_VISIT,
  DELETE_PATINET,
  SERACH_GET_PATINET,
  EMPTY_LIST_PATINET_IN_SEARCH,
} from "../../constants/patinetConstatnt";
import api, { logout } from "../../constants/api";
import store from "../../store";
import { getToken } from "../../reducers/userReducers";
import { FOLLOW_UP } from "../todayPatient/EachVisition/create_visit_reducer";
var dateFormat = require("dateformat");

export default function searchPatinetReducer(
  state = {
    visits: [],
  },
  action
) {
  switch (action.type) {
    case SERACH_GET_PATINET:
      return (state = {
        ...state,
        orginalvisits: action.payload.visits,
        visits: action.payload.visits,
        total: action.payload.total,
      });
    case ADD_PATINET: {
      return {
        ...state,
        total: state.total + 1,
        visits: [...state.visits, action.payload],
      };
    }
    case EMPTY_LIST_PATINET_IN_SEARCH: {
      return {
        ...state,

        visits: [],
      };
    }

    case DELETE_PATINET: {
      const newDrugs = [...state.visits].filter((drug) => {
        return drug.id !== action.payload.id;
      });
      return {
        ...state,
        total: state.total - 1,
        visits: [...newDrugs],
      };
    }
    case ADD_VISIT: {
      let temp = [...state.visits];
      const date = dateFormat(new Date(),"yyyy-mm-dd HH:MM:ss");
      temp.forEach((e) => {
        console.log(e);
        if (e.id == action.payload) {
          e["visit_last_one"]["follow_date"] = date;
          e["visit_last_one"]["created_at"] = date;
        }
      });
      return {
        ...state,
        visits: [...temp],
      };
    }
    case FOLLOW_UP: {
      let temp = [...state.visits];
      temp.forEach((e) => {
        if (e.id == action.payload) {
          e["visit_last_one"]["follow_date"] = dateFormat(
            new Date(),
           "yyyy-mm-dd HH:MM:ss"
          );
        }
      });
      return {
        ...state,
        visits: [...temp],
      };
    }

    default:
      return state;
  }
}

export async function getSearchPatient(from, to, query, page, code, take) {
  store.dispatch({ type: EMPTY_LIST_PATINET_IN_SEARCH });

  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  let param =
    "from=" +
    (from == null || from == undefined || from == "" ? "2020-01-01" : from) +
    "&" +
    ("to=" + (to == null || to == undefined || to == "" ? "3020-01-01" : to)) +
    "&" +
    "_take=" +
    take +
    "&" +
    "_page=" +
    page +
    "&";
  if (isNaN(query.trim())) {
    param = param + "patient_name=" + query.trim();
  } else {
    param = param + "&_filter=" + query.trim();
  }
  if (code != undefined && code != "") {
    param = param + "&id=" + code?.trim();
  }
  param = param + "&sort=asc&order=id";

  try {
    const response = await api.get("visits?" + param, config);

    const data = response.data;

    store.dispatch({ type: SERACH_GET_PATINET, payload: data });
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    }
  }
}
