import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  deleteinvistigations,
  updateinvistigations,
} from "./investgation_operation";
import MultiSelectComp from "./MultiSelectComp";

import FirstPrintPage from "../../../../print/printTremnats/Invistigation_print";
import { isPharmacyOrLab } from "../../../../reducers/userReducers";

const InvestigationSectionLab = (props) => {
  let isComplete = props.state.state == "completed";
  const [state, setState] = useState([...props.state.invistigation]);
  useEffect(() => {
    setState([...props.state.invistigation]);
  }, []);
  let list = [];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  state?.forEach((element, index) => {
    list.push(
      <tr key={element.id}>
        <td>{element.test.name}</td>
        {isPharmacyOrLab() ? null : (
          <td>
            <button
              disabled={isComplete}
              className="btn btn-danger"
              onClick={(event) => {
                deleteinvistigations(element.id);
              }}
            >
              <i className="fas fa-trash text-white"> </i>
            </button>
          </td>
        )}
        <td>
          {" "}
          <input
            value={element.result_number ?? ""}
            onChange={(e) => {
              let data = state;
              data[index].result_number = e.target.value;
              setState([...data]);
            }}
            type="text"
            className="form-control ml-1 mr-1"
          />
        </td>

       
      </tr>
    );
  });
  return (
    <section>
      <div className="card">
        <div className="card-header bg-info">
          <h4 className="text-uppercase d-inline-block">Investigation </h4>
        </div>
        <div className="card-body">
          {isPharmacyOrLab ? null : (
            <MultiSelectComp state={props.state}></MultiSelectComp>
          )}

          <table className="table table-bordered mt-2">
            <thead className="bg-info">
              <tr>
                <th>Test Name</th>

                {isPharmacyOrLab() ? null : <th>Delete</th>}
                <th>Result</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
          <div className=" text-center">
            <button
              className="btn btn-primary btn-lg mt-3 w-25"
              onClick={(event) => {
                console.log(state.map((e) => e.id));
                updateinvistigations({
                  id: state.map((e) => e.id),
                  visit_id: props.state.id,
                  patient_id: props.state.patient.id,
                  test_id: state.map((e) => e.test.id),
                  result_number: state.map((e) => e.result_number),
                  result_state: state.map((e) => e.result_state),
                });
              }}
            >
              Save
            </button>
          </div>
          <div hidden>
            {" "}
            <FirstPrintPage
              ref={componentRef}
              state={props.state}
              type={"invistigation"}
            />
          </div>
          <div className=" text-center">
            <button
              className="btn btn-primary btn-lg mt-3 w-25"
              disabled={isComplete}
              onClick={(event) => {
                handlePrint();
              }}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestigationSectionLab;
