 import swal from 'sweetalert';
import api from '../constants/api';
import {
  ADD_MAIN_TEST_REQUEST,
  ADD_MAIN_TEST_SUCCESS,
  ADD_MAIN_TEST_FAIL,
  GET_MAIN_TEST_REQUEST,
  GET_MAIN_TEST_SUCCESS,
  GET_MAIN_TEST_FAIL,
  DELETE_MAIN_TEST_REQUEST,
  DELETE_MAIN_TEST_SUCCESS,
  DELETE_MAIN_TEST_FAIL,
  UPDATE_MAIN_TEST_REQUEST,
  UPDATE_MAIN_TEST_SUCCESS,
  UPDATE_MAIN_TEST_FAIL,
} from '../constants/mainTextConstatnts';
import {getToken} from "../reducers/userReducers";

export const addMainTestAction = (
  token,
  name,
  description,reset
) => async dispatch => {
  try {
    dispatch({
      type: ADD_MAIN_TEST_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.post(
      'clinic-test-category/create',
      { name, description },
      config
    );
    dispatch({
      type: ADD_MAIN_TEST_SUCCESS,
      payload: data,
    });    await swal("Good job!", "Main Tes was added successfully", "success");

    reset();
  } catch (error) {    await swal("Opps!", "Failed to add main test", "error");

    dispatch({
      type: ADD_MAIN_TEST_FAIL,
    });
  }
};

export const getMainTestAction = token => async dispatch => {
  try {
    dispatch({
      type: GET_MAIN_TEST_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.get(
      'clinic-test-category',
      config
    );
    dispatch({
      type: GET_MAIN_TEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_MAIN_TEST_FAIL,
    });
  }
};

export const deleteMAinTest = (token, id) => async dispatch => {
  try {
    dispatch({
      type: DELETE_MAIN_TEST_REQUEST,
    });

    const { data } = await api.delete(
      `clinic-test-category/delete/${id}`,

      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer  ${getToken()}`,
        },
      }
    );
    dispatch({
      type: DELETE_MAIN_TEST_SUCCESS,
      payload: data,
    });
    await swal("Good job!", "Main Tes was deleted successfully", "success");

  } catch (error) {    await swal("Opps!", "Failed to delete main test", "error");

    dispatch({
      type: DELETE_MAIN_TEST_FAIL,
    });
  }
};

export const updateMAinTest = (
  token,
  id,
  name,
  description,reset
) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_MAIN_TEST_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.put(
      `clinic-test-category/update/${id}`,
      { name, description },
      config
    );
    dispatch({
      type: UPDATE_MAIN_TEST_SUCCESS,
      payload: data,
    });
    reset()
    await swal("Good job!", "Main Tes was updated successfully", "success");

  } catch (error) {
    await swal("Opps!", "Failed to update main test", "error");

    dispatch({
      type: UPDATE_MAIN_TEST_FAIL,
    });
  }
};
