import swal from "sweetalert";
import { getToken } from "../../../../reducers/userReducers";
import api, { logout } from "../../../../constants/api";
import store from "../../../../store";
import {
  ADD_INVESTIGATION,
  DELETE_INVESTIGATION,
  UPDATE_INVESTIGATION,
} from "../../../../constants/patinetConstatnt";

export async function saveinvistigations(
  test_id,
  result_number,
  result_state,
  visit_id,
  resetSelectField
) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "invistigations/create",
      {
        test_id,
        result_number,
        result_state,
        visit_id,
      },
      config
    );

    const data = response.data;
    store.dispatch({
      type: ADD_INVESTIGATION,
      payload: {
        visit_id: visit_id,
        data: data,
      },
    });
    resetSelectField();
    await swal("Good job!", "invistigations was saved successfully", "success");

    return true;
  } catch (error) {
    console.log(error);
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save invistigations", "error");
    }
    return false;
  }
}

export async function updateinvistigations(datas) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put("invistigations/update", datas, config);

    const data = response.data;

    store.dispatch({ type: UPDATE_INVESTIGATION, payload: data[0] });
    await swal("Good job!", "invistigations was saved successfully", "success");

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save invistigations", "error");
    }
    return false;
  }
}

export async function deleteinvistigations(id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.delete(
      "invistigations/delete/" + id,

      config
    );

    const data = response.data;
    store.dispatch({ type: DELETE_INVESTIGATION, payload: id });

    await swal(
      "Good job!",
      "invistigations was deleted successfully",
      "success"
    );

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to delete invistigations", "error");
    }
    return false;
  }
}
