import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import SimpleImageSlider from "react-simple-image-slider";
import pdfPlaceHolder from "../todayPatient/EachVisition/VisitationImageSection/pdf.jpg";

const Slideshow = (props) => {
  const images = props.state.patient.cvs;

  let imagesList = [];
  let pdfs = [];

  images?.forEach((e, index) => {
    if (e.url.toString().toLowerCase().endsWith("pdf") == false) {
      imagesList.push(e);
    } else {
      pdfs.push(
        <td>
          <a href={e.url} target="_blank">
            {e.created_at.toString().split(" ")[0]}
            <br />
            {e.url.toString().toLowerCase().endsWith("pdf") == false ? (
              <img width="100" height="100" src={e.url} />
            ) : (
              <img width="100" height="100" src={pdfPlaceHolder} />
            )}
          </a>
        </td>
      );
    }
  });
  return (
    <div>
      PDF Files
      {pdfs}
      {imagesList.length == 0 ? (
        <div>No Image Found</div>
      ) : (
        <SimpleImageSlider
          width={900}
          height="130%"
          images={imagesList}
          onClick={(idx, event) => {
            console.log("click ,", idx);
            window.open(imagesList[idx].url, "_blank");
          }}
          showNavs={true}
          showBullets={true}
        />
      )}
    </div>
  );
};
export default Slideshow;
