import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./todayPatient.css";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllTodayPatient, getPatient } from "./todayPatinetReducer";
import { createVisti, followUp } from "./EachVisition/create_visit_reducer";
import ReactPaginate from "react-paginate";
import { isPharmacyOrLab } from "../../reducers/userReducers";
var dateFormat = require("dateformat");

function TodayPatientScreen() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [phoneSort, setPhoneSort] = useState(false);
  const [isToday, setIsToday] = useState(true);

  function changePhoneStateSort() {
    setPhoneSort(!phoneSort);
  }

  const { visits, isAll, total } = useSelector(
    (state) => state.todayPatinetReducer
  );

  const [page, setPage] = useState(0);
  const [take, setTake] = useState(100);

  useEffect(() => {
    if (visits?.length === 0) {
      getAllTodayPatient(take, search.query, search.code, page);
    }
  }, []);
  console.log("sdgsdgsd", dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"));
  const [search, setSearch] = useState({ query: "", code: "" });
  let patientList = [];
  let index = 0;
  const GetFormattedDate = () => {
    var todayTime = new Date();
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();

    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return year + "-" + month + "-" + day;
  };
  const history = useHistory();

  visits?.forEach((visit, indexIn) => {
    let ind = "";

    let color = false;

    if (
      GetFormattedDate() ===
        visit.visit_last_one.follow_date.toString().split(" ")[0] &&
      GetFormattedDate() !==
        visit.visit_last_one.created_at.toString().split(" ")[0]
    ) {
      color = true;
    } else {
      index = index + 1;
      ind = index;
    }

    patientList.push(
      <tr
        onClick={(e) => {
          history.push(`/tabsVisit/${visit.id}`, { patientKey: visit });
        }}
        key={indexIn}
        style={color == true ? { backgroundColor: "DarkGray" } : {}}
      >
        <td>{ind}</td>
        <td>
          {" "}
          <p className="text-dark text-decoration-none">{visit.id}</p>
        </td>{" "}
        <td>
          {" "}
          <p className="text-dark text-decoration-none">{visit.fullname}</p>
        </td>
        <td>
          <p className="text-dark text-decoration-none">{visit.phone}</p>
        </td>
        {isPharmacyOrLab() ? null : (
          <td className="text-center">
            {GetFormattedDate() ===
            visit.visit_last_one.follow_date.toString().split(" ")[0] ? null : (
              <button
                className="btn btn-outline-info"
                onClick={async (e) => {
                  let isCreated = await createVisti(visit.id);

                  if (isCreated == true) {
                    getPatient(
                      startDate,
                      endDate,
                      search.query,
                      page,
                      take,
                      search.code
                    );
                  }
                }}
              >
                New Visit
              </button>
            )}
          </td>
        )}
        <td className="text-center text-danger text-bold " id="sizeFont">
          <ins>
            {" "}
            <p className="text-red text-u"> {visit.visits}</p>
          </ins>
        </td>
        {isPharmacyOrLab() ? null : (
          <td className="text-center">
            {GetFormattedDate() ===
            visit.visit_last_one.follow_date.toString().split(" ")[0] ? null : (
              <button
                className="btn btn-outline-warning"
                onClick={(e) => {
                  followUp(visit.id);
                }}
              >
                Follow up
              </button>
            )}
          </td>
        )}
        <td className="text-center">{visit?.visit_last_one?.follow_date}</td>
      </tr>
    );
  });

  return (
    <div className="rounded-top">
      <div className="card rounded-top">
        <div className="card-header bg-info">Today Patient</div>
        <div className="card-body">
          <div className="active-purple-4 mt-1 text-center">
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-md-6 text-center">
                <div className="row text-center">
                  <div className="">
                    <span className="m-2">From</span>
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>

                  <div>
                    <span className="m-2">{"  "}To </span>
                    <DatePicker
                      className="form-control"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-md-6">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    className="form-control my-0 py-1"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={search.query}
                    onChange={(e) => {
                      setSearch({ ...search, query: e.target.value });
                    }}
                  />{" "}
                  <input
                    className="form-control my-0 py-1"
                    type="search"
                    placeholder="Code"
                    aria-label="Code"
                    value={search.code}
                    onChange={(e) => {
                      setSearch({ ...search, code: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2">
              <button
                className="btn btn-primary w-50"
                onClick={(e) => {
                  setPage(0);
                  getPatient(
                    startDate,
                    endDate,
                    search.query,
                    0,
                    take,
                    search.code
                  );
                }}
              >
                search
              </button>
            </div>

            <div className="mt-2">
              <button
                className="btn btn-primary w-50"
                onClick={(e) => {
                  getAllTodayPatient(take, search.query, search.code, page);
                }}
              >
                Get All Today Patinet
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mr-1 ml-1">
        <table className="table table-bordered w-100">
          <thead id="tableHeader" className="w-100">
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>
                <span> Patient name</span>
              </th>
              <th>
                <span>Patient phone</span>
              </th>

              {isPharmacyOrLab() ? null : (
                <th>
                  <span> New Visit</span>
                  <span className="float-right"></span>
                </th>
              )}

              <th>visitations</th>
              {isPharmacyOrLab() ? null : <th>Follow Up</th>}
              <th>
                <span>Last Visit</span>
              </th>
            </tr>
          </thead>
          <tbody>{patientList}</tbody>
        </table>
        {total > 0 ? (
          <div className="center d-flex justify-content-center align-items-center mb-5">
            <div className="mr-2">
              <ReactPaginate
                forcePage={0}
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                onPageChange={(ev) => {
                  if (isToday == false) {
                    getPatient(
                      startDate,
                      endDate,
                      search.query,
                      ev.selected,
                      take,
                      search.code
                    );
                  } else {
                    getAllTodayPatient(
                      take,
                      search.query,
                      search.code,
                      ev.selected
                    );
                  }
                  setPage(ev.selected);
                }}
                pageNum={page}
                forcePage={page}
                pageCount={total / take}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            <div>
              <select
                value={take}
                onChange={(e) => {
                  setTake(e.target.value);
                  if (isToday == false) {
                    getPatient(
                      startDate,
                      endDate,
                      search.query,
                      page,
                      e.target.value,
                      search.query
                    );
                  } else {
                    getAllTodayPatient(
                      e.target.value,
                      search.query,
                      search.code,
                      page
                    );
                  }
                }}
                className="form-control border-info "
                placeholder="count"
              >
                <option value="10" selected>
                  10
                </option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        ) : (
          <div>
            <br />
          </div>
        )}
      </div>
    </div>
  );
}

export default TodayPatientScreen;
