import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { saveNextVisitAction, updateNextVisitAction } from "../../../actions/NextVisitAction";

const NextVisitPage = (props) => {
  let nextVisitDate = props?.state?.next_visit?.next_visit_date;
  // console.log()
  const [disable, setDisble] = useState(false);
  const [date, setDate] = useState(nextVisitDate);
  let isComplete = props.state.state == "completed";
  let nextVisitId = props?.state?.next_visit?.id;
  const handleSubmit = (event) => {
    event.preventDefault();
    if (nextVisitId) {
     updateNextVisitAction(nextVisitId,props.state.id,date)
      return;
    } else {
      saveNextVisitAction(props.state.id, date);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="card card-outline">
          <div className="card-header bg-info">
            <h4 className="text-uppercase d-inline-block">Next Visit Date</h4>{" "}
            <i className="fas fa-image text-red ml-2"> </i>
          </div>
          <div className="card-body w-100">
            <div className="form-group input-group-lg">
              <label> Next Visit Date</label>
              <input
                value={date}
                onChange={(e) => setDate(e.target.value)}
                type="date"
                className="form-control d-inline"
                aria-label="With textarea"
              />
            </div>

            <button
              type="submit"
              disabled={isComplete || disable}
              className="btn btn-success w-100 mt-4"
            >
              Sumbit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NextVisitPage;
