import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";

import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  createVisti,
  followUp,
} from "../todayPatient/EachVisition/create_visit_reducer";
import { Link, useHistory } from "react-router-dom";
import { getSearchPatient } from "./searchPatinetReducer";

function SearchScreen() {
  const [phoneSort, setPhoneSort] = useState(false);

  function changePhoneStateSort() {
    setPhoneSort(!phoneSort);
  }

  const [searchState, setSearch] = useState({
    startDate: "",
    endDate: "",
    query: "",
    code: "",
  });
  const GetFormattedDate = () => {
    var todayTime = new Date();
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();

    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return year + "-" + month + "-" + day;
  };
  const userlogin = useSelector((state) => state.userLogin);
  const [page, setPage] = useState(0);

  const { userInfo } = userlogin;
  const { visits, total } = useSelector((state) => state.searchPatinetReducer);
  const [take, setTake] = useState(10);
  let patientList = [];
  let index = 0;
  const handleSubmit = (pr) => {
    pr.preventDefault();
    setPage(0);
    getSearchPatient(
      searchState.startDate,
      searchState.endDate,
      searchState.query,
      0,
      searchState.code,
      take
    );
  };
  const history = useHistory();

  visits?.forEach((visit) => {
    index++;
    patientList.push(
      <tr
        onClick={(e) => {
          console.log("c;icksf");
          history.push(`/tabsVisit/${visit.id}`, { patientKey: visit });
        }}
        key={index}
      >
        <td>{index}</td>
        <td>
          <p className="text-dark text-decoration-none">{visit.id}</p>
        </td>{" "}
        <td>
          <p className="text-dark text-decoration-none">{visit.fullname}</p>
        </td>
        <td>
          <p className="text-dark text-decoration-none">{visit.phone}</p>
        </td>
        <td className="text-center">
          {GetFormattedDate() ==
          visit.visit_last_one.follow_date.toString().split(" ")[0] ? null : (
            <button
              className="btn btn-outline-info"
              onClick={(e) => {
                createVisti(visit.id, userInfo.token);
              }}
            >
              New Visit
            </button>
          )}
        </td>
        <td className="text-center text-danger text-bold " id="sizeFont">
          <ins>
            {" "}
            <p className="text-red text-u"> {visit.visits}</p>
          </ins>
        </td>
        <td className="text-center">
          {GetFormattedDate() ==
          visit.visit_last_one.follow_date.toString().split(" ")[0] ? null : (
            <button
              className="btn btn-outline-warning"
              onClick={(e) => {
                followUp(visit.id);
              }}
            >
              Follow up
            </button>
          )}
        </td>
        <td className="text-center">{visit?.visit_last_one?.created_at}</td>
      </tr>
    );
  });
  return (
    <div className="rounded-top">
      <form className="card rounded-top" onSubmit={handleSubmit}>
        <div className="card-header bg-info">Search In Patient</div>
        <div className="card-body">
          <div className="form mb-3 w-100">
            <div className="active-purple-4">
              <label htmlFor="patinetCodeSearch" className="">
                Search
              </label>
              <input
                id="patinetCodeSearch"
                className="form-control"
                type="text"
                onChange={(event) => {
                  setSearch({ ...searchState, query: event.target.value });
                }}
                placeholder="Search"
                aria-label="Search"
              />{" "}
              <label htmlFor="patinetCodeSearch" className="">
                Code
              </label>
              <input
                id="patinetCodeSearch"
                className="form-control"
                type="text"
                onChange={(event) => {
                  setSearch({ ...searchState, code: event.target.value });
                }}
                placeholder="Code"
                aria-label="Code"
              />
            </div>

            <div className="active-purple-4 mt-3">
              <label htmlFor="diagnosisSearch" className="col-form-label">
                between Two date
              </label>
              <div className="row">
                <div className="col-6">
                  <span>From</span>{" "}
                  <input
                    type="date"
                    className="form-control"
                    selected={searchState.startDate}
                    onChange={(date) =>
                      setSearch({
                        ...searchState,
                        startDate: date.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-6">
                  <span>To</span>{" "}
                  <input
                    type="date"
                    className="form-control"
                    selected={searchState.endDate}
                    onChange={(date) =>
                      setSearch({
                        ...searchState,
                        endDate: date.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="text-center mt-3">
              <button type="submit" className="btn btn-outline-info w-50">
                Search
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="mr-1 ml-1">
        <table className="table table-bordered w-100">
          <thead id="tableHeader" className="w-100">
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>
                <span> Patient name</span>
              </th>
              <th>
                <span>Patient phone</span>
              </th>
              <th>
                <span> New Visit</span>
              </th>

              <th>visitations</th>
              <th>Follow Up</th>
              <th>Last Visit</th>
            </tr>
          </thead>
          <tbody>{patientList}</tbody>
        </table>
        {total > 0 ? (
          <div className="center d-flex justify-content-center align-items-center mb-5">
            <div className="mr-2">
              {" "}
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                onPageChange={(ev) => {
                  getSearchPatient(
                    searchState.startDate,
                    searchState.endDate,
                    searchState.query,
                    ev.selected,
                    searchState.code,

                    take
                  );

                  setPage(ev.selected);
                }}
                pageNum={page}
                forcePage={page}
                pageCount={total / 10}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            <div>
              <select
                value={take}
                onChange={(e) => {
                  setTake(e.target.value);
                  getSearchPatient(
                    searchState.startDate,
                    searchState.endDate,
                    searchState.query,
                    searchState.code,
                    page,
                    e.target.value
                  );
                }}
                className="form-control border-info "
                placeholder="count"
              >
                <option value="10" selected>
                  10
                </option>
                <option value="50">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SearchScreen;
