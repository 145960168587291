import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  addPationReducer,
  getPationReducer,
  updatePationReducer,
  deletPationReducer,
} from "./reducers/pationReducer";
import {
  addLabsReducer,
  getLabReducer,
  deleteLabReducer,
} from "./reducers/labReducer";
import {
  addDrugsReducer,
  getDrugsReducer,
  deleteDrugsReducer,
  updateDrugsReducer,
} from "./reducers/drugsReducer";
import {
  addMainTestReducer,
  getMainTestReducer,
  deleteMainTestReducer,
  updateMainTestReducer,
} from "./reducers/mainTestReducer";
import {
  addOccupationReducer,
  getOccupationReducer,
  updateOccupationReducer,
} from "./reducers/occupationReducer";
import {
  referalDoctorrducer,
  getReferalDoctorrducer,
  deleteReferalDoctorrducer,
  updateReferalDoctorrducer,
} from "./reducers/referalDoctor";
import { userLoginReducr } from "./reducers/userReducers";
import todayPatinetReducer from "./component/todayPatient/todayPatinetReducer";
import eachVisitDetalis from "./component/todayPatient/EachVisition/create_visit_reducer";
import userReducer from "./component/account/usersReducer";
import searchPatinetReducer from "./component/search/searchPatinetReducer";
import reportReducer from "./component/report/reportReducer";
import { getNextVisitReducer } from "./reducers/NextVisitReducer";

const reducer = combineReducers({
  userLogin: userLoginReducr,
  userReducer: userReducer,
  addPation: addPationReducer,
  getpations: getPationReducer,
  updatePation: updatePationReducer,
  deletePation: deletPationReducer,
  refDoctor: referalDoctorrducer,
  getDoctor: getReferalDoctorrducer,
  deleteRefDoctor: deleteReferalDoctorrducer,
  updateRefDoctor: updateReferalDoctorrducer,
  addOccupation: addOccupationReducer,
  getOccupation: getOccupationReducer,
  updateOccupation: updateOccupationReducer,
  addDrug: addDrugsReducer,
  getDrugs: getDrugsReducer,
  reportReducer: reportReducer,
  deleteDrugs: deleteDrugsReducer,
  updateDrugs: updateDrugsReducer,
  addMainTest: addMainTestReducer,
  getMainTest: getMainTestReducer,
  deleteMainTest: deleteMainTestReducer,
  updateMainTest: updateMainTestReducer,
  searchPatinetReducer: searchPatinetReducer,
  addLab: addLabsReducer,
  getLab: getLabReducer,
  deleteLab: deleteLabReducer,
  todayPatinetReducer: todayPatinetReducer,
  eachVisitDetalis: eachVisitDetalis,
  getNextVisitReducer,
});

const userInfoFormStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
// const userRegisterInfoFormStorage = localStorage.getItem('userInfo')
//   ? JSON.parse(localStorage.getItem('userInfo'))
//   : null;

const initialState = {
  userLogin: { userInfo: userInfoFormStorage },
  // userRegister: { userInfo: userRegisterInfoFormStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
