import {
  FETCH_NEXT_VISIT_FAIL,
  FETCH_NEXT_VISIT_REQUEST,
  FETCH_NEXT_VISIT_SUCCESS,
} from "../constants/NextVisit";

export const getNextVisitReducer = (
  state = { loading: true, nextVisits: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_NEXT_VISIT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_NEXT_VISIT_SUCCESS:
      return {
        loading: false,
        nextVisits: action.payload.data,
        total: action.payload.total,
      };
    case FETCH_NEXT_VISIT_FAIL:
      return {
        loading: false,
        nextVisits: [],
        total: 0,
        error: action.payload,
      };
    default:
      return state;
  }
};
