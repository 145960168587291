import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMainTestAction,
  addMainTestAction,
  deleteMAinTest,
  updateMAinTest,
} from "../../actions/mainTestAction";

function AddMainTest() {
  const userlogin = useSelector((state) => state.userLogin);
  const getMainTests = useSelector((state) => state.getMainTest);
  const [name, setName] = useState();
  const [updateID, setUpdateID] = useState();
  const dispatch = useDispatch();
  const { userInfo } = userlogin;
  const { getMainTestData } = getMainTests;
  const [disable, setDisble] = useState(false);

  useEffect(() => {
    dispatch(getMainTestAction(userInfo.token));
  }, []);

  const reset = () => {
    setName("");
  };
  const [search, setSearch] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();

    if (name == null || name == undefined || name == "") {
      return;
    }

    const { userInfo } = userlogin;
    setDisble(true);
    if (updateID == null) {
      await dispatch(
        addMainTestAction(userInfo.token, name, "description", reset)
      );
      setDisble(false);
      await dispatch(getMainTestAction(userInfo.token));
    } else {
      await dispatch(
        updateMAinTest(userInfo.token, updateID, name, "description", reset)
      );
      setDisble(false);
      setUpdateID(null);
      dispatch(getMainTestAction(userInfo.token));
    }
  };

  const deleteHandler = async (id) => {
    const { userInfo } = userlogin;
    await dispatch(deleteMAinTest(userInfo.token, id));
    await dispatch(getMainTestAction(userInfo.token));
  };

  const editdeleteHandler = (id, name, description) => {
    setName(name);
    setUpdateID(id);
  };
  let counterTest = 0;
  let mainTestForm = [];
  getMainTestData?.forEach((mainTest) => {
    if (mainTest.name?.includes(search)) {
      counterTest++;
      mainTestForm.push(
        <tr key={counterTest}>
          <td>{counterTest}</td>
          <td>{mainTest.name}</td>

          <td>
            <button
              onClick={() =>
                editdeleteHandler(
                  mainTest.id,
                  mainTest.name,
                  mainTest.description
                )
              }
              className="bg-success btn-circle btn-sm"
            >
              <i className="fas fa-pen"> </i>
            </button>
            <button
              onClick={() => deleteHandler(mainTest.id)}
              className="bg-red btn-circle btn-sm"
            >
              <i className="fas fa-trash"> </i>
            </button>
          </td>
        </tr>
      );
    }
  });

  return (
    <div className="card">
      <div className="card-header bg-info">
        <p>Add Main Test</p>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <form className="form" onSubmit={submitHandler}>
              <div className="form-group">
                <label htmlFor="testName" className="col-form-label">
                  Name
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="testName"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/*<div className="form-group">*/}
              {/*  <label htmlFor="testDesc" className=" col-form-label">*/}
              {/*    Description*/}
              {/*  </label>*/}
              {/*  <textarea*/}
              {/*   required*/}
              {/*    type="textArea"*/}
              {/*    className="form-control"*/}
              {/*    id="testPrice"*/}
              {/*    placeholder="Normal Range"*/}
              {/*    rows="4"*/}
              {/*    value={description}*/}
              {/*    onChange={(e) => setDescription(e.target.value)}*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={disable}
                >
                  <i className="fas fa-plus"></i>{" "}
                  {updateID == null ? "Add" : "Save"}
                </button>
              </div>
            </form>
            {updateID != null && updateID != undefined ? (
              <div
                className="form-group"
                onClick={(e) => {
                  setName("");
                  setUpdateID(null);
                }}
              >
                <button className="btn btn-danger w-100">Cancel</button>
              </div>
            ) : null}
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            {/*table*/}
            <div className="row m-3">
              {/*<div className="col-3 justify-content-center">*/}
              {/*  <div className="select2-purple input-group">*/}
              {/*    <label className="mr-2 mt-1">show</label>*/}
              {/*    <select className="form-control" name="state">*/}
              {/*      <option value="1">10</option>*/}
              {/*      <option value="2">20</option>*/}
              {/*      <option value="3">30</option>*/}
              {/*      <option value="4">40</option>*/}
              {/*    </select>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-8">
                <div className="input-group">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search by Test"
                    aria-label="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text purple lighten-3"
                      id="basic-text1"
                    >
                      <i className="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <table className="table table-bordered  w-100">
              <thead>
                <tr className="bg-gradient-cyan light rounded-top">
                  <th>#</th>
                  <th>Name</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody> {mainTestForm}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMainTest;
