import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { isPharmacyOrLab } from "../../reducers/userReducers";
function SlideBar() {
  const userinfo = useSelector((state) => state.userLogin);
  const { userInfo } = userinfo;

  return (
    <div className="wrapper">
      <aside className="main-sidebar sidebar-dark-primary elevation-4 position-fixed ">
        {/* Brand Logo */}
        <Link to="/" className="brand-link">
          <h2 className="brand-text font-weight-light">Farman Clinic</h2>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image"></div>
            <div
              className="info"
              style={{
                color: "white",
              }}
            >
              {userInfo ? userInfo.user_info?.username : "Username"}
            </div>
          </div>

          {/* SidebarSearch Form */}

          {/* Sidebar Menu */}
          {isPharmacyOrLab() ? null : (
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="sidebar"
                data-accordion="false"
              >
                {/*if open each  link add sidebar-open and active*/}

                <li className="nav-item">
                  <Link to="/newInvoice" className="nav-link">
                    <i className="nav-icon fas fa-plus-circle" />
                    New Patient
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/todayPatient" className="nav-link">
                    <i className="nav-icon fas fa-user-injured" />
                    Today Patient
                  </Link>
                </li>

                
                <li className="nav-item">
                  <Link to="/nextVisit" className="nav-link">
                    <i className="nav-icon fas fa-user-injured" />
                    NextVisit
                  </Link>
                </li>

                <div>
                  {" "}
                  <li className="nav-item">
                    <Link to="/occupation" className="nav-link">
                      <i className="nav-icon fas fa-briefcase"></i>
                      Occupation
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/referralDoctor" className="nav-link">
                      <i className="nav-icon fas fa-user-md" />
                      Referral Doctor
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/addTest" className="nav-link">
                      <i className="nav-icon fas fa-list-alt" />
                      Laboratory
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/addMainTest" className="nav-link">
                      <i className="nav-icon fas fa-vial" />
                      MainTest
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/addDrugs" className="nav-link">
                      <i className="nav-icon fas fa-prescription-bottle-alt" />
                      Drugs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/users" className="nav-link">
                      <i className="nav-icon fas fa-user" />
                      Users
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/search" className="nav-link">
                      <i className="nav-icon fas fa-search" />
                      Search
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/reportAll" className="nav-link">
                      <i className="nav-icon fas fa-list" />
                      Report
                    </Link>
                  </li>
                </div>
              </ul>
            </nav>
          )}
          {/* /.sidebar-sidebar */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}

export default SlideBar;
