import api, { logout } from "../constants/api";
import {
  CREATE_NEXT_VISIT_SUCCESS,
  FETCH_NEXT_VISIT_SUCCESS,
} from "../constants/NextVisit";
import { getToken } from "../reducers/userReducers";
import swal from "sweetalert";
import store from "../store";

export const getNextVisitAction =
  (page, perPage,searchParams) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: {
        page,
        perPage,
        name:searchParams.name,
        phone:searchParams.phone,
        code:searchParams.code,
        from:searchParams.dateFrom,
        to:searchParams.dateTo,
      },
    };
    // console.log(searchParams)
    try {
      const response = await api.get("next-visit", config);

      const data = response.data;
      // console.log(data)
      if (response.status == 200) {
        dispatch({
          type: FETCH_NEXT_VISIT_SUCCESS,
          payload: { data: data.data, total: data.total },
        });
        //   return;
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        logout();
      } else if (error.response?.status === 422) {
        await swal("Opps!", "The given data was invalid", "error");
        return false;
      }
      return false;
    }
  };

export async function saveNextVisitAction(visit_id, date) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "next-visit/create",
      {
        visit_id: visit_id,
        nextVisitDate: date,
      },
      config
    );

    const data = response.data;
    // console.log(response);

    if (response.status == 201) {
      await swal("Good job!", "Next Visit was saved successfully", "success");
      console.log("dat : ", data);
      store.dispatch({
        type: CREATE_NEXT_VISIT_SUCCESS,
        payload: { visit_id: visit_id, data: data },
      });
      //   return;
    }
  } catch (error) {
    console.log(error);
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    }
    return false;
  }
}

export async function updateNextVisitAction(id, visit_id, date) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "next-visit/update/" + id,
      {
        visit_id: visit_id,
        nextVisitDate: date,
      },
      config
    );

    const data = response.data;
    // console.log(response);

    if (response.status == 200) {
      await swal("Good job!", "Next Visit was update successfully", "success");
    }
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    }
    return false;
  }
}

export async function updateNextVisitCompleteAction(id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "next-visit/update-complete/" + id,
      {},
      config
    );

    const data = response.data;
    // console.log(response);

    if (response.status == 200) {
      await swal(
        "Good job!",
        "Next Visit was complete successfully",
        "success"
      );
    }
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    }
    return false;
  }
}
