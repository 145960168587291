export const ADD_PATINET = "ADD_PATINET";
export const GET_PATINET = "GET_PATINET";
export const EDIT_PATINET = "EDIT_PATINET";
export const EMPTY_LIST_PATINET = "EMPTY_LIST_PATINET";
export const EMPTY_LIST_PATINET_IN_SEARCH = "EMPTY_LIST_PATINET_IN_SEARCH";

export const DELETE_PATINET = "DELETE_PATINET";
export const SERACH_GET_PATINET = "SERACH_GET_PATINET";
export const ADD_VISIT = "ADD_VISIT";

export const GET_PATINET_VISIT = "GET_PATINET_VISIT";
export const EDIT_GROWTH_TABLE = "EDIT_GROWTH_TABLE";
export const EDIT_GROWTH_CHART = "EDIT_GROWTH_CHART";
export const EDIT_HISTORY_VISIT = "EDIT_HISTORY_VISIT";
export const SET_TO_DEF = "SET_TO_DEF";
export const UPDATE_INVESTIGATION = "UPDATE_INVESTIGATION";
export const ADD_INVESTIGATION = "ADD_INVESTIGATION";

export const DELETE_INVESTIGATION = "DELETE_INVESTIGATION";
export const GET_PATINET_SEARCH = "GET_PATINET_SEARCH";
